.main__productions {
    overflow: hidden;
    min-height: 100vh;
}

.head__container__right {
    font-family: 'bebasneue', sans-serif;
    color: white;
    font-size: 32px;
    position: absolute;
    top: 32px;
    left: 40px;
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    width: 120px;
}

.head__container__left {
    position: absolute;
    top: 32px;
    right: 40px;
    opacity: 0;
}

.production__container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
    overflow: hidden;
    gap: 64px;
}

.production__container .production__container__left {
    margin-top: 40vh;
    width: 45%;
    opacity: 0;
}

.production__description {
    color: white;
    font-family: 'bebasneue', sans-serif;
    font-weight: 400;
    font-size: 17px;
    width: 60%;
    margin: auto;
}

.production__marquee__container {
    opacity: 0;
    display: flex;
    gap: 12px;
}

.production__marquee__top, .production__marquee__bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    gap: 8px;
    height: calc(428px * 6);
}

.production__marquee__top .cover, .production__marquee__bottom .cover {
    width: 290px;
    height: 420px;
    object-fit: cover;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.production__marquee__top {
    animation: carousel 12s linear infinite; 
}

.production__marquee__bottom {
    animation: carousel 12s linear infinite reverse; 
}

.production__marquee__top:hover, .production__marquee__bottom:hover {
    animation-play-state: paused;
}

.production__marquee__top .marquee__container:hover .cover, .production__marquee__bottom .marquee__container:hover .cover {
    filter: blur(5px);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.production__marquee__bottom div, .production__marquee__top div {
    position: relative;
    cursor: pointer;
}

.production__marquee__bottom .sub__marquee__container, .production__marquee__top .sub__marquee__container {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    top: 20%;
    width: 100%;
    height: 0;
    gap: 64px;
    transition: 0.3s ease-in-out;
    opacity: 0;
}


.production__marquee__bottom .sub__marquee__container div, .production__marquee__top .sub__marquee__container div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    gap: 4px;
    transition: 0.3s ease-in-out;
}

.production__marquee__bottom .sub__marquee__container div img, .production__marquee__top .sub__marquee__container div img{
    width: 40px;
    height: 40px;
    object-fit: cover;
    transition: 0.3s ease-in-out;
}

.production__marquee__top .marquee__container:hover .sub__marquee__container, .production__marquee__bottom .marquee__container:hover .sub__marquee__container {
    transition: 0.3s ease-in-out;
    opacity: 1;
}

.production__marquee__bottom p, .production__marquee__top p {
    font-size: 30px;
    font-family: 'bebasneue', sans-serif;
    font-weight: 200;
    /* mix-blend-mode: difference; */
    text-align: center;
    background-color: transparent;
    color: white;
}

@keyframes carousel {

    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(calc(-428px * 3));
    }
}

.production__container__circle {
    bottom: 56px;
    left: 64px;
    position: absolute;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;

}

.production__container__circle__text {
    width: 100%;
    height: 100%;
    position: absolute;
    animation: rotate 12s linear infinite;
}

.production__container__circle__text span {
    position: absolute;
    font-family: 'bebasneue', sans-serif;
    left: 50%;
    font-size: 15px;
    transform-origin: 0 60px;
    text-transform: uppercase;
    color: #E2E641;
    font-weight: 600;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.production__container__circle__text__static {
    width: 120px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.production__container__circle__text__static p {
    margin: 0;
    padding: 0;
} 

.production__container__circle__text__static p:first-child {
    font-size: 48px;
    font-family: 'bebasneue', sans-serif;
    color: #E2E641;
    line-height: 0.7;
}

.production__container__circle__text__static p:last-child {
    font-size: 13px;
    font-family: 'bebasneue', sans-serif;
    color:#E2E641;
    text-transform: uppercase;
}

@media screen and (min-width: 821px) and (max-height: 650px) {

    .production__container {
        gap: 32px;
    }

    .production__container .production__container__left {
        margin-top: 20vh;
        width: 40%;
        opacity: 0;
    }

    .production__marquee__top .cover, .production__marquee__bottom .cover {
        width: 230px;
        height: 360px;
    }
 }


@media screen and (min-width: 600px) and (max-width: 821px){
    .production__marquee__container {
        bottom: 0;
        height: 60%;
    }
}

@media screen and (max-width: 600px){
    .production__marquee__container {
        height: 472px;
    }
}

@media screen and (max-width: 821px) {

    .head__container__right {
        position: absolute;
        top: 24px;
        /* padding: 24px 40px; */
    }

    .production__container {
        position: relative;
        flex-direction: column;
        gap: 32px;
    }

    .production__container .production__container__left {
        margin-top: 124px;
        width: 100%;
    }

    .production__description {
        margin-top: 8px;
        width: 80%;
        overflow: hidden;
    }

    .production__marquee__container {
        margin-top: 48px;
        width: 100%;
        overflow: hidden;
        gap: 8px;
    }

    .production__marquee__top, .production__marquee__bottom {
        gap: 8px;
        overflow: hidden;
        width: 50%;
        height: calc(283px * 6);
    }

    .production__marquee__top {
        animation: carousel2 10s linear infinite; 
    }
    
    .production__marquee__bottom {
        animation: carousel2 10s linear infinite reverse; 
    }

    .production__marquee__top .cover, .production__marquee__bottom .cover {
        width: 100%;
        height: 100%;
    }

    .marquee__container {
        width: 100%;
        height: 275px;
    }

    .production__container__circle {
        position: relative;
        top: 32px;
        left: 24px;
    }
    .production__marquee__bottom .sub__marquee__container , .production__marquee__top .sub__marquee__container  {
        top: 38%;
        gap: 0px;
    }

    .production__marquee__bottom .sub__marquee__container .chevron , .production__marquee__top .sub__marquee__container .chevron {
        display: none;
    }

    .production__marquee__bottom .sub__marquee__container div, .production__marquee__top .sub__marquee__container div {
        margin-top: 4px;
        flex-direction: row;
    }

    .production__marquee__bottom .sub__marquee__container p, .production__marquee__top .sub__marquee__container p{
        font-size: 22px;
    }

    .production__marquee__bottom .sub__marquee__container div img, .production__marquee__top .sub__marquee__container div img{
        height: 30px;
        width: 30px;
    }
    
}

@media screen {
    
}

@keyframes carousel2 {

    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(calc(-283px * 3));
    }
}

