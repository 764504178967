.header__discographie__container {
    margin-top: 88px;
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;
}

.header__discographie__container .header__discographie__right__container {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-right: 15%;
    color: #ACACAC;
}

.header__discographie__container .header__discographie__right__container p {
    font-family: 'bebasneue', sans-serif;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #ACACAC;
    padding: 6px 16px;
    border-radius: 20px;
    transition: .2s ease-in-out;
}

.header__discographie__container .header__discographie__right__container p:last-child {
    border: none;
    color: white;   
}

.discographie__container {
    display: flex;
    overflow-y: auto;
    gap: 8px;
    width: calc(100% - 10px);
    position: absolute;
    bottom: 8px;
    opacity: 0;
    align-items: flex-end;
    overflow-x: auto;
}

.disco__container {
    overflow: hidden;
}

.disco__container a {
    text-decoration: none;
}

.discographie__container:first-child {
    margin-left: 5px;
}

.discographie__item__container h5 {
    font-family: 'bebasneue', sans-serif;
    font-weight: 400;
    font-size: 22px;
    color: white;
    margin-bottom: 24px;
    transition: .2s ease-in-out;
}

.discographie__item__container p:nth-child(2) {
    font-family: 'bebasneue', sans-serif;
    font-size: 14px;
    color: #D0D0D0;
    transition: .1s ease-in-out;    
}

.discographie__item__container p:nth-child(3) {
    display: flex;
    align-items: center;
    gap: 16px;
    font-family: 'bebasneue', sans-serif;
    color: #D0D0D0;
    margin-top: 8px;
    margin-bottom: 56px;
    font-size: 14px;
    transition: .1s ease-in-out;    
}

.discographie__item__container .cover__disco {
    height: 45vh;
    width: 45vh;
    object-fit: cover;
    padding-bottom: 2px;
    transition: .1s ease-in-out;    
}

.discographie__item__container:hover {
    cursor: pointer;
}

.discographie__item__container:hover img {
    transform: scale(1.02);
}

.discographie__item__container:hover p, .discographie__item__container:hover h5 {
    color: #E2E641;
}

@media screen and (min-width: 821px) and (max-height: 650px){ 

    .head__main__container__right {
        width: 120px;
    }

    .header__discographie__container {
        margin-top: 72px;
    }
    .header__discographie__title {
        font-size: 30px;
    }

    .discographie__item__container .cover__disco {
        width: 38vh;
        height: 38vh;
    }

    .header__discographie__container .header__discographie__right__container p {
        font-size: 12px;
    }

    .discographie__item__container p:nth-child(3) {
        margin-bottom: 10px;
    }

    .discographie__item__container h5 {
        font-size: 14px;
    }

    .discographie__item__container p:nth-child(2) {
        font-size: 12px;
    }
}

@media screen and (max-width: 821px) and (max-height: 1000px) and (min-height: 841px){ 
    .discographie__item__container .cover__disco {
        width: 300px;
        height: 350px;
    }
}

@media screen and (max-width: 700px) and (max-height: 655px) {

    .discographie__item__container h5 {
        font-size: 19px;
    }

    .discographie__item__container p:nth-child(2) {
        font-size: 13px;
    }

    .discographie__item__container p:nth-child(3) i:first-child img {
        width: 25px;
    }

    .discographie__item__container p:nth-child(3) i:last-child img {
        width: 24px;
    }

    .header__discographie__container {
        flex-direction: column;
    }

    .header__discographie__container .header__discographie__right__container {
        gap: 12px;
        padding-right: 0;
        margin-top: 16px;
    }
    .header__discographie__container .header__discographie__right__container p {
        font-size: 12px;
        padding: 4px 12px;
    }

    .discographie__item__container .cover__disco {
        height: 37vh;
        width: 37vh;
    }

    .discographie__item__container p:nth-child(3) {
        margin-bottom: 28px;
    }
}


@media screen and (max-width: 821px) and (max-height: 700px) {
    .header__discographie__container {
        margin-top: 16px;
    }
}

@media screen and (max-width: 821px) and (max-height: 750px) and (min-height: 700px) {
    /* .discographie__item__container .cover__disco {
        width: 300px;
        height: 300px;
    } */

    .header__discographie__container {
        margin-top: 24px;
    }

}


@media screen and (max-width: 821px) and (max-height: 840px) and (min-height: 750px) {
    .discographie__container {
        bottom: 0;
        height: 70%;
    }
    /* .discographie__item__container .cover__disco {
        width: 350px;
        height: 350px;
    } */
}

@media screen and (max-width: 821px) and (min-height: 655px) {

    .discographie__item__container p:nth-child(3) {
        margin-bottom: 24px;
    }

    .header__discographie__container {
        flex-direction: column;
    }

    .header__discographie__container .header__discographie__right__container {
        gap: 12px;
        padding-right: 0;
        margin-top: 24px;
    }
    .header__discographie__container .header__discographie__right__container p {
        font-size: 12px;
        padding: 4px 12px;
    }
    
}

@media screen and (max-width: 700px) and (min-height: 655px)  {
    .header__discographie__container {
        margin-top: 16px;
    }

    .discographie__item__container .cover__disco {
        height: 40vh;
        width: 40vh;
    }
    
    .header__discographie__title {
        margin-top: 32px;
        font-size: 30px;
    }

}
@media screen and (max-width: 700px) and (max-height: 655px)  {

    .discographie__item__container .cover__disco {
        height: 40vh;
        width: 40vh;
    }
    
    .header__discographie__title {
        margin-top: 32px;
        font-size: 30px;
    }

}

@media screen and (max-width: 700px) and (max-height: 520px)  {

    .discographie__item__container .cover__disco {
        height: 35vh;
        width: 35vh;
    }
    
    .header__discographie__title {
        margin-top: 32px;
        font-size: 30px;
    }

    .discographie__item__container p:nth-child(3) {
        margin-bottom: 10px;
    }

    .discographie__item__container h5 {
        font-size: 14px;
    }

    .discographie__item__container p:nth-child(2) {
        font-size: 12px;
    }

}




