.clips_main {
    background: #171614;
    overflow-y: hidden;
}

.clips__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    width: fit-content;
    margin: 40px auto 16px;
    overflow: hidden;
}


.clips__container__head__pictures {
    margin-top: 40px;
    display: flex;
    gap: 12px;
}

.clips__container__head__pictures a {
    text-decoration: none;
    /* max-width: 34vw; */
    width: 50%;
}

.clips__container__head__pictures p {
    color: white;
    font-size: 13px;
    font-family: 'bebasneue', sans-serif;
    padding-bottom: 4px;
}

.clips__container__head__pictures img:first-child {
    transition: .2s ease-in-out;
    border-radius: 8px;
    object-fit: cover;
}

.clips__container__head__pictures img:last-child {
    position: absolute;
    z-index: 1;
    bottom: 32px;
    left: 32px;
    transition: .2s ease-in-out;
    object-fit: cover;
}
    
.clips__container__head__pictures div:hover img {
    transform: scale(1.03);
    transition: .2s ease-in-out;
    cursor: pointer;
}

.clips__container__head__left, .clips__container__head__right {
    height: 100%;
}

.header__img {
   object-fit: cover;
   width: 100%; 
   height: 100%;
}


.clips__container__body {
    margin-top: 16px;
    opacity: 0;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.clips__container__body div {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: 'bebasneue', sans-serif;
}

.clips__container__body div:first-child {
    margin-top: 26px;
    font-weight: 600;
}

.clips__container__body div p {
    width: calc(100% / 6);
    text-align: center;
    font-size: 14px;
}


.clips__container__body__list__item {
    background: #1E1E1E;
    padding: 12px 0;
    margin-bottom: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: .2s ease-in;
    border: 0;
}

.clips__container__body a {
    text-decoration: none;
}

.clips__container__body__list__item p {
    cursor: pointer;
}

.clips__container__body__list__item:hover {
    scale: 1.02;
    color: #E2E641;
    transition: .3s ease-in-out;
}

.clips__container__body__list__item img {
    width: calc(100% / 6);
    height: 70px;
    border-radius: 4px;
    object-fit: cover;
}

.clips__container__body__list__item img:last-child {
    width: 30px;
    height: 30px;
}

.clips__container__body__mobile {
    display: none;
}



@media screen and (min-width: 821px) {
    .clips__container__head__pictures {
        margin-top: 40px;
        margin-bottom: 16px;
    }    

    .clips__container__head__pictures a {
        max-width: 34vw;
    }
}

@media screen and (min-width: 821px) and (max-width: 1200px) {
    .clips__container__head__pictures img:last-child {
        bottom: 0px;
        width: 25px;
        left: 20px;        
    }
}

@media screen and (max-width: 821px) {

    .clips__container__head__pictures {
        flex-direction: column;
        justify-content: center;
    }   

    .clips__container {
        width: 93%;
        overflow-x: hidden;
    } 

    .clips__container h1 {
        text-align: center;
        width: 70%;
    }

    .clips__container__head__pictures {
        width: 100vw;
        justify-content: center;
        align-items: center;
    }

    .clips__container__head__pictures a {
        width: 70%;
    }


    .header__img {
        max-width: 90vw;
        height: 100%;
        border-radius: 0;   
    }


        /* .clips__container__head__left, .clips__container__head__right {
            width: 80%;
            margin: auto;
            overflow-x: hidden;
        } */

    .clips__container__body {
        display: none;
        overflow-x: hidden;
    }

    .clips__container__body__mobile {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        width: 100%;
        transform: translateY(100%);
    }

    .clips__container__body__mobile p {
        color: white;
        font-size: 13px;
        font-family: 'bebasneue', sans-serif;
        padding-bottom: 4px;
    }

    .clips__container__body__mobile__list__item {
        display: flex;
        flex-direction: row;
        /* justify-content: space-between; */
        align-items: center;
        background: #1E1E1E;
        padding: 12px 16px;
        margin-bottom: 12px;
        border-radius: 4px; 
        cursor: pointer;
    }


    .clips__container__body__mobile__list__item:hover p, .clips__container__body__mobile__list__item:hover div p:nth-child(2),
    .clips__container__body__mobile__list__item:hover div p:nth-child(3), .clips__container__body__mobile__list__item:hover p:nth-child(2)
    {
        color: #E2E641;
    }

    .clips__container__body__mobile__list__item div:first-child {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .clips__container__body__mobile__list__item div:nth-child(2) {
        width: 60%;
        margin-left: 12%;
    }

    .clips__container__body__mobile__list__item img {
        width: 120px;
        height: 100%;
        border-radius: 4px;
    }

    .clips__container__body__mobile__list__item p {
        color: white;
        text-decoration: none;
    }

    .clips__container__body__mobile__list__item p:nth-child(1){
        font-weight: 600;
        font-size: 14px;
        width: 100%;
    }

    .clips__container__body__mobile__list__item div p:nth-child(2), .clips__container__body__mobile__list__item div p:nth-child(3) {
        font-size: 14px;
        color: #ACACAC;
    }

    .clips__container__body__mobile__list__item p:nth-child(2){
        font-size: 12px;
        color: #ACACAC;
        padding-right: 8px;
    }

    .clips__container__body__mobile__list__item p:last-child img {
        width: 30px;
    }
}

@media screen and (max-width: 650px) {
    .clips__container__head__pictures img:last-child {
        bottom: 20px;
        left: 20px;
        width: 22px;
        height: 22px;
    }

    .clips__container__body__mobile__list__item div:nth-child(2) {
        width: 60%;
        margin-left: 5%;
    }

    .clips__container__body__mobile__list__item p:last-child img {
        width: 25px;
    }
}

a:-webkit-any-link{
    text-decoration:none !important;
    }