.inputAdmin {
    background-color: rgb(227, 227, 227);
    border: none;
    outline: none;
    padding: 8px 12px;
    border-radius: 4px;
    min-width: 250px;
}

.buttonConnect {
    background-color: #2593ff;
    text-align: center;
    padding: 6px 8px;
    border-radius: 4px;
    cursor: pointer;
}

.buttonConnect:hover {
    opacity: .9;
}