.dashboardsub {
    padding: 4rem;
}

.dashboard__header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    padding: 1.5rem;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: rgb(55, 55, 55);
}

.dashboard__header p {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 3px;
}

.dashboard__header p:hover {
    background-color: rgba(110, 110, 110, 0.437);
}

.dashboard__body {
    padding: 2rem;
    margin-top: 1rem;
    min-height: 60vh;
    height: auto;
    background-color: rgb(55, 55, 55);
}


.dashboard__table {
    padding: .5rem;
    width: 100%;
    color: rgb(29, 29, 29);
    border-collapse: collapse;
}

.dashboard__table .header__table {
    background: rgb(27, 27, 27);
    height: 35px;
    color: rgb(220, 220, 220);
}

.dashboard__table .header__table th {
    font-weight: bold;
    padding: 12px;
}

.dashboard__table tbody tr {
    height: 35px;
    cursor: pointer;
}

.dashboard__table tbody tr:hover {
    opacity: .7;
}


.dashboard__table tbody tr:nth-child(odd){
    background-color: white;
}

.dashboard__table tbody tr:nth-child(even){
    background-color: rgb(232, 232, 232);
}

.modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.104);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
}

.modal {
    background-color: white;
    min-width: 30vw;
    padding: 1rem 2rem;
    position: fixed;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal .modalSub {
    display: flex;
    gap: 24px;
}

.modal .modalSub div {
    width: 100%;
}

.modal .modalSub div p {
    font-size: 14px;
    font-weight: 600;
}

.modalSub {
    margin-top: 12px;
}

.modalSub input {
    width: 100%;
    padding: 5px;
}

.subActions {
    margin-top: 24px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    gap: 16px;
}

.enregistrer {
    cursor: pointer;
    background: rgb(163, 163, 254);
    padding: 8px 12px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.enregistrer:hover {
    opacity: .9;
}


.annuler {
    cursor: pointer;
    background: rgb(199, 199, 199);
    padding: 8px 12px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
}

.supprimer {
    cursor: pointer;
    background: rgb(248, 153, 153);
    padding: 8px 12px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
}

.supprimer:hover, .annuler:hover{
    opacity: .9;
}

.addButton {
    cursor: pointer;
    background: rgb(163, 163, 254);
    padding: 8px 12px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 8px;
}

.uplaodPhotoButton {
    cursor: pointer;
    background: rgb(163, 163, 254);
    padding: 8px 12px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 8px;
    border: none;

}

.popup {
    position: absolute;
    top: 32px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 8px 16px;
    border-left: 6px solid green;
    border-radius: 4px;
    color: green;
}

.unlog {
    padding: 8px 16px;
    color: rgb(226, 19, 19);
}

.unlog:hover {
    background-color: rgba(255, 17, 17, 0.403);
    border-radius: 3px;
}