* {
  padding: 0;
  margin: 0;
}

html {
  font-family: 'bebasneue', sans-serif;
  scroll-behavior: smooth;
  min-height: 100vh;
  background: #171614;
}

_::-webkit-full-page-media, _:future, :root .panel {
  background: #171614;
}

body > #root > .main {
  height: 100vh;
  background: #171614;
}


h1 {
  color: white;
  font-family: 'bebasneue', sans-serif;
  font-weight: 400;
  font-size: 40px;
  text-transform: uppercase;
}

.Title {
  font-size: 80px;
}

.logoHome {
  opacity: 0;
  width: 400px;
}

.logo {
  display: none;
  opacity: 0;
  width: 400px;
  z-index: 2;
}

.transition_content {
  font-family: "deathrattle";
  display: none;
  font-size: 64px;
  opacity: 0;
}

/* .head__main__container__right {
  z-index: 1;
  font-family: bebasneue;
  color: white;
  font-size: 32px;
  position: absolute;
  top: 32px;
  left: 40px;
  cursor: pointer;
  opacity: 0;
} */

.head__main__container__right {
  cursor: pointer;
  width: 150px;
  position: absolute;
  top: 0px;
}

.head__main__container__left {
  position: absolute;
  top: 32px;
  right: 40px;
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: 0;
}

.head__main__container__left p {
  font-family: 'bebasneue', sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  color: #acacac;
  mix-blend-mode: difference;
}

.head__main__container__left p:nth-child(odd){
  cursor: pointer;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 4px;
  position: absolute;
}

/* Track */
::-webkit-scrollbar-track {
  background: #171614;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #F9F9F9;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #F9F9F9;
}

@media screen and (max-width: 768px) {

    body > #root > .main {
      height: 100%;
      background: #171614;
    }

    h1 {
      line-height: 0.95;
    }

    .head__main__container__right {
      width: 100px;
    }

    .Title {
      font-size: 48px;
    }
    .logoHome {
      opacity: 0;
      width: 300px;
    }

    .logo {
      opacity: 0;
      width: 300px;
    }
    
    
}

@font-face {
  font-family: 'deathrattle';
  src: local('deathrattle'),
       url('./fonts/deathrattlebb_reg.ttf') format('woff');
}