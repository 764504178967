.main__home {
    top: 0;
    height: 100vh;
}

.head__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0px;
}


.title__container {
    margin-top: 32px;
    display: flex;
    justify-content: center;
}

.title {
    font-family: "deathrattle" ;
    color: #fff;
    font-size: 70px;
}

.content__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    margin: auto;
    margin-top: 80px;
    flex-direction: row;
    gap: 24px;
}

.content__container div {
    box-shadow: 4px 4px 6px rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    border-radius: 16px;
    transition: all 0.3s ease-in-out;
    height: 380px;
    background: rgba(40,40,40);
}

.content__container div:hover, .content__container div:active{
    transform: scale(1.05);
    cursor: pointer;
}


.content__container div p:nth-child(1), .content__container div p:nth-child(2){
    display: none;
}


/* @media screen and (min-width: 767px) { */

    .content__container div:hover img:nth-child(3), .content__container div:active img:nth-child(3) {
        display: none;
    }

    .content__container div:hover p:nth-child(1), .content__container div:hover p:nth-child(2),
    .content__container div:active p:nth-child(1), .content__container div:active p:nth-child(2)
    {
        display: block;
        color: #2a2929;
    }

    .content__container div:hover:nth-child(1){
        background-color: #8806CE;
    }

    .content__container div:hover:nth-child(2){
        background-color: #68B8E6;
    }

    .content__container div:hover:nth-child(3){
        background-color: #ED0010;
    }

    .content__container div:hover:nth-child(4){
        background-color: #FD6A02;
    }

    .content__container div:hover:nth-child(5){
        background-color: #FC0FC0;
    }

/* } */
    


.content__container div:nth-child(1){
    color: white;
}

.content__container div:nth-child(2){
    color: #E2E641;
}

.content__container div:nth-child(3){
    color: #c299df;
}

.content__container div:nth-child(4){
    color: #50bb8b;
}

.content__container div:nth-child(5){
    color: #5cb0c8; 
}


.content__container div p:nth-child(3) {
    font-size: 160px;
    font-family: 'bebasneue', sans-serif;
    font-weight: 600;
    text-align: center;
    margin: auto;
    pointer-events: none;
}

.marquee{
    font-family: 'bebasneue', sans-serif;
    color: #fff;
    white-space: nowrap;
    animation: marquee 10s infinite linear; /* notice the infinite */
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 600;
    pointer-events: none;
}

.inversed {
    animation: marqueeInverse 10s infinite linear;
}

.marquee1:after, .marquee2:after, .marquee3:after, .marquee4:after, .marquee5:after{
    content: attr(datacustomattributes);
}

.iframe__spotify {
    border: none;
    position: absolute;
    bottom: 0;
    right: 16px;
    width: 550px;
}

.OverflowMenu_wrapper__pSRdd {
    display: none;
}

@keyframes marquee{
    0% {
        transform: translateX(0)
    }
    100% {
        transform: translateX(-300%)
    }
}

@keyframes marqueeInverse{
    0% {
        transform: translateX(-300%)
    }
    100% {
        transform: translateX(0)
    }
}


.footer__container {
    position: absolute;
    display: flex;
    bottom: 24px;
    justify-content: center;
    align-items: center;  
    gap: 24px; 
    margin: auto;
    width: 100%;
    z-index: 0;
}
 
.footer__container img {
    width: 40px;
    cursor: pointer;
}

.transition__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    z-index: 100;
}

.sound__container {
    position: absolute;
    bottom: 16px;
    left: 48px;
    width: 0%;
    z-index: 1;
}

.sound__container img {
    text-align: center;
    height: 20px;
    border: 1px solid white;
    padding: 8px;
    border-radius: 50%;
    color: white;
}

.content__container .content__container__card img {
    width: 80%;
    margin: auto;
}

@media screen and (min-width: 821px){
    .main__home .content__container {
        height: 48vh;
    }

    .main__home .content__container div {
        height: 100%;
    }
}

@media screen and (min-width: 821px) and (max-height: 650px) {
    .main__home .title__container {
        margin-top: 0;
    }

    .main__home .title__container .title {
        font-size: 35px;
    }

    .main__home .content__container {
        margin-top: 32px;
    }

    .main__home .content__container div {
        height: 250px;
    }

    .main__home .content__container__card div img {
        width: 40px;
    }

    .main__home .iframe__spotify {
        height: 100px;
    }

    .main__home .footer__container {
        bottom: 108px;
    }
}

@media (min-width: 1200px) and (max-width: 1600px){
    .iframe__spotify {
        width: 400px;
    }

    .content__container {
        width: 95%;
        margin-top: 80px;
    }
}

@media (min-width: 900px) and (max-width: 1200px){

    .main__home {
        overflow-x: scroll;
    }

    .iframe__spotify {
        width: 350px;
    }

    .content__container {
        width: 95%;
        margin-top: 80px;
    }

    .content__container div {
        height: 300px;
    }

    .content__container div p:nth-child(3) {
        font-size: 120px;
    }

    .footer__container {
        bottom: 124px;
    }

    .iframe__spotify {
        width: 500px;
        display: flex;
        justify-content: center;
        right: 50%;
        transform: translate(50%, 0);
        margin: auto;
        margin-top: 16px;
    }
}

@media (min-width: 767px) and (max-width: 900px){
    .content__container {
        flex-direction: column;
        width: 55%;
    }

    .main__home {
        height: 100%;
    }

    .footer__container {
        margin-top: 64px;
        position: relative;
    }

    .iframe__spotify {
        margin-top: 16px;
        position: relative;
        width: 90%;
        display: flex;
        justify-content: center;
        right: 0;
        margin: auto;
     }

     .content__container .content__container__card img {
        width: 55%;
        margin: auto;
    }
}

@media screen and (max-width: 767px) {

    .marquee{
        font-size: 32px;
    }

    .content__container .content__container__card img {
        width: 60%;
        margin: auto;
    }

    .main__home {
        height: 100%;
    }

    .main__home .title {
        margin-top: 16px;
    }

    .content__container {
        flex-direction: column;
        width: 75%;
    }

    .content__container div {
        height: 220px;
    }

    .content__container div p:nth-child(3) {
        font-size: 80px;
    }

    .footer__container {
        position: relative;
        display: flex;
        padding: 64px 0 8px 0;
        justify-content: center;
        align-items: center;  
        gap: 24px; 
        margin: auto;
        width: 100%;
    }

    .sound {
        padding-top: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sound__container {
        position: relative;
        left: 0;
        bottom: 0;
        width: auto;
    }

    .iframe__spotify {
       position: relative;
       width: 90%;
       display: flex;
       justify-content: center;
       right: 0;
       margin: auto;
    }

    .content__container div img:nth-child(3){
        margin: auto;
    }
    
}

