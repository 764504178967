.about {
    overflow-x: hidden;
}

.about__container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    margin: auto;
    width: 53%;
    padding-top: 104px;
}

.about__container__image {
    opacity: 0;
}


.about__container .about__container__image img {
    height: 550px;
    width: 350px;
    object-fit: cover;
}

.about__container .about__container__text {
    font-family: 'bebasneue', sans-serif;
    color: white;
    font-weight: 300;
    font-size: 15px;
    
}

.about__container .about__container__text h1 {
    font-size: 64px;
    position: absolute;
    right: 160px;
    top: 80px;
    height: 80px;
    transform: translateX(300%);
 }
 

.about__container .about__container__text div {
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 64px;
    transform: translateX(300%);
}


.about__container .about__container__text div ul {
    padding-left: 24px;
}

.about__container .about__container__text div ul:nth-child(3) {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.about__container .about__container__text div ul:nth-child(5) {
    font-weight: bold;
}

.about__studio__container {
    margin-top: 248px;
}

.about h2 {
    text-align: center;
    color: white;
    font-family: 'bebasneue', sans-serif; 
    font-weight: 400;
    font-size: 40px;
    text-transform: uppercase;
}

.about__studio__pictures__head {
    display: flex;
    justify-content: center;
    margin: auto;
    max-height: fit-content;
    gap: 6px;
    background-color: #ffffff;
    width: fit-content;
    padding: 6px;
    object-fit: cover;
}


.about__studio__pictures__head img {
    min-width: 50%;
    object-fit: cover;
    max-height: 530px;
    /* max-height: 600px; */
    max-width: 370px;
}

.about__studio__pictures__body img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 370px;
    max-height: 262px;
}

.about__studio__pictures__body {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.about__studio__foot {
    display: flex;
    justify-content: center;
    margin: 40px 96px;
    align-items: center;
}

.about__studio__foot a:nth-child(1){
    font-family: 'bebasneue', sans-serif;
    font-weight: 700;
    font-size: 20px;
    background-color: #ffffff;
    text-decoration: none;
    color: black;
    padding: 12px 56px;
    font-weight: 300;
    border-radius: 30px;
    text-transform: uppercase;
    transition: .2s ease-in-out;
}

.about__studio__foot a:nth-child(1):hover {
    cursor: pointer;
    background: #E2E641;
}

.about__studio__bottom__actions__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10%;
    margin: 48px 0;
}

.about__studio__bottom__actions__container .about__studio__bottom__item {
    display: flex;
    gap: 32px;
    max-width: 30%;
    align-items: center;
}

.about__studio__bottom__actions__container .about__studio__bottom__item div:last-child {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.about__studio__bottom__item p:first-child {
    color: white;
    font-family: 'bebasneue', sans-serif;
    font-weight: 600;
    /* font-size: 14px; */
}

.about__studio__bottom__item p:last-child {
    color: white;
    font-family: 'bebasneue', sans-serif;
    font-weight: 300;
    width: 70%;
    /* font-size: 14px; */
}

.about__label__container {
    display: flex;
    margin: 248px auto;
    align-items: flex-start;
    justify-content: center;
    width: 60%;
    gap: 48px;
}

.about__label__container p:first-child {
    color: #fff;
    font-family: 'bebasneue', sans-serif;
    font-size: 30px;
    font-weight: 500;
    padding: 0;
    margin: 0;
    line-height: 0.9;
}

.about__label__container div:nth-child(1){
    width: 40%;
}

.about__label__container h2 {
    text-align: left;
}

.about__label__container p:nth-child(3){
    margin-top: 24px;
    font-family: 'bebasneue', sans-serif;
    color: white;
    font-size: 30px;
}

.about__label__container p:nth-child(4){ 
    font-family: 'bebasneue', sans-serif;
    font-size: 16px;
    color: white;
}

.about__label__container__right img {
    height: 400px;
}

@media screen and (min-width: 821px) and (max-height: 650px){
    .about__container {
        width: 70%;
        padding-top: 104px;
    }

    .about__container .about__container__text h1 {
        font-size: 50px;
    }

    .about__container .about__container__text div {
        margin-top: 36px;
    }

    .about__label__container {
        width: 70%;
        margin: 248px auto;
        gap: 24px;
    }

}

@media screen and (max-width: 768px) {

    .about__container {
        flex-direction: column;
        align-items: center;
        padding-top: 64px;
        width: 90%;
    }

    .about__container .about__container__image img {
        width: 300px;
        height: 400px;
        object-fit: cover;
    }

    .about__container .about__container__text h1 {
        position: relative;
        font-size: 32px;
        right: 0;
        top: 16px;
        height: auto;
        text-align: center;
    }

    .about__container .about__container__text div {
        margin-top: 32px;
        padding-left: 8px;
    }

    .about__studio__container {
        margin-top: 152px;
    }

    .about h2 {
        font-size: 24px;
    }

    .about__studio__pictures__head {
        width: 90%;
    }

    .about__studio__foot {
        margin: 24px 16px;
    }

    .about__studio__foot a:nth-child(1) {
        padding: 12px 24px;
        font-size: 16px;
    }

    .about__studio__bottom__actions__container {
        flex-direction: column;
        gap: 40px;
    }

    .about__studio__bottom__actions__container .about__studio__bottom__item {
        max-width: 80%;
    }

    .about__studio__bottom__actions__container .about__studio__bottom__item div:last-child {
        gap: 8px;
    }

    .about__studio__bottom__item p:first-child {
        font-size: 14px;
    }

    .about__studio__bottom__item p:last-child {
        width: 100%;
        font-size: 14px;
    }

    .about__label__container {
        flex-direction: column;
        margin: 0;
        width: 90%;
        margin: auto;  
        gap: 16px;
        margin-bottom: 48px;
    }

    .about__label__container div:nth-child(1) {
        width: 100%;  
        margin-top: 64px;
    }

    .about__label__container p:nth-child(4) {
        font-size: 14px;
    }   

    .about__label__container__right {
        display: flex;
        justify-content: center;
    }

    .about__label__container__right img {
        width: 70%;
        height: auto;
        margin: auto;
        align-self: center;
    }
}